import React, { useState } from "react";
import axios from "axios";
import queryString from "query-string";
import "./App.css";

const CheckIcon = () => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22033_54290)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.5 0C22.9219 0 0.5 22.4219 0.5 50C0.5 77.5781 22.9219 100 50.5 100C78.0781 100 100.5 77.5781 100.5 50C100.5 22.4219 78.0781 0 50.5 0Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.6797 33.1445C75.8906 34.3555 75.8906 36.3477 74.6797 37.5586L45.3828 66.8555C44.7773 67.4609 43.9766 67.7734 43.1758 67.7734C42.375 67.7734 41.5742 67.4609 40.9688 66.8555L26.3203 52.207C25.1094 50.9961 25.1094 49.0039 26.3203 47.793C27.5312 46.582 29.5234 46.582 30.7344 47.793L43.1758 60.2344L70.2656 33.1445C71.4766 31.9141 73.4687 31.9141 74.6797 33.1445Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_22033_54290">
        <rect
          width="100"
          height="100"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

function App() {
  const [submitted, setSubmitted] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [error, setError] = useState("");
  const [result, setResult] = useState<any>();
  const parsed = queryString.parse(window.location.search);
  const { id, sender, hash } = parsed;

  const submitOrder = () => {
    setError("");
    axios
      .post(
        "https://salesinfinityx.com/api/offline/event/messenger/submit-order",
        {
          id,
          sender,
          hash,
          order_id: orderNumber,
        }
      )
      .then(function (response) {
        // handle success
        setResult(response?.data);
        setSubmitted(true);
      })
      .catch(function (error) {
        // handle error
        setError(error?.response?.data?.message);
      })
      .finally(function () {
        // always executed
      });
  };

  return (
    <div className="h-screen flex flex-col justify-center p-4">
      {submitted ? (
        <>
          <div className="flex-1 flex flex-col justify-center items-center">
            <CheckIcon />
            <h1 className="text-primary text-4xl font-semibold my-6">
              Successful!
            </h1>
            <p className="text-center text-sm">{result?.message}</p>
          </div>

          <div className="w-full fixed left-0 bottom-0 p-3">
            <button className="bg-primary rounded-lg text-white w-full p-3 text-sm">
              <a href={result?.data?.url}>Visit URL</a>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex-1 flex flex-col justify-center">
            <h1 className="text-primary text-2xl font-semibold mb-6">
              Submit Order No. / ส่งหมายเลขออเดอร์
            </h1>
            <p className="mb-6 text-sm">
              Thank you very much for shopping with us. Please submit the Order
              Number in the field below in order to receive the order
              confirmation and order tracking (if any) to your Messenger /
              ขอบคุณคุณลูกค้าที่ช้อปปิ้งกับเรา
              โปรดส่งหมายเลขออเดอร์ในช่องด้านล่าง
              เพื่อรับการยืนยันออเดอร์และติดตามสถานะของออเดอร์ (ถ้ามี) ทาง
              Messenger
            </p>
            <div>
              <label
                htmlFor="order-number"
                className="font-semibold block mb-2 text-sm"
              >
                Order Number / หมายเลขออเดอร์
              </label>
              <input
                id="order-number"
                className="rounded border px-3 py-3 w-full text-sm focus:outline-primary"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e?.target?.value)}
              />
              {error && <p className="text-[#F44336] text-sm mt-2">{error}</p>}
            </div>
          </div>
          <div className="w-full fixed left-0 bottom-0 p-3">
            <button
              disabled={!orderNumber}
              className="bg-primary rounded-lg text-white w-full p-3 disabled:bg-gray-200 text-sm"
              onClick={() => submitOrder()}
            >
              Submit / ส่ง
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
